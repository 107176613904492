import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MasterDataService } from '../service/masterdata.service';
import { BehaviorSubject } from 'rxjs';
import { LineItem, OutboundDC, ShipmentMockRequest, ShipmentMockRequestType, ShipmentParams } from '../model/shipment.model';
import { Constants } from '../service/constant.service';
import * as log from '../AppInsightsLogger';
import { ShipmentService } from '../service/shipment.service';
import { error } from 'console';
import { GoodReceiptMockRequest, Orders } from '../model/goodreceiptrequest.model';
import { GoodReceiptService } from '../service/goodreceipt.service';
import { GoodReceiptMockResponse } from '../model/goodreceiptresponse.model';
import { LoadPlanMockRequest } from '../model/loadplan.model';
import { LoadPlanPartner } from '../model/masterdata.model';
import { LoadPlanService } from '../service/loadplan.service';
import { HttpClient } from '@angular/common/http';
import { ShipmentmockerService } from '../service/shipmentmocker.service';
import { LoadplanmockerService } from '../service/loadplanmocker.service';
import { GoodsreceiptmockerService } from '../service/goodsreceiptmocker.service';
import { InventoryServiceService } from '../service/inventory-service.service';
import { AdjDetail, InventoryAdjMockRequest, Parameter } from '../model/inventorySCG.model';
import { AcknowledgeMockerService } from '../service/acknowledge-mocker.service';
import { AckknowledgementMockerRequest, ParameterAck, Transac } from '../model/ackmocker.model';
import { OutboundDeliveryMockerRequest, ParameterOBD } from '../model/outboundmocker.model';
import { ScgService } from '../service/scg.service';
import { OutboundMockerService } from '../service/outbound-mocker.service';
import { AsnMockerRequest, ParameterASN } from '../model/ASNmocker.model';
import { AsnMockerService } from '../service/asn-mocker.service';
import { TradeProformaMockerRequest, TradeProformaParams } from '../model/tradePorforma.model';
import { TradeporformaService } from '../service/tradeporforma.service';
import { BookingRequestParameters, BrCreateMockerRequest, BookingRequestShipmentline } from '../model/brcreatemocker.model';
import { BrMockersService } from '../service/br-mockers.service';
import { InventoryAdviceLine, InvSnapshotFSLMockerRequest, Parameters } from '../model/invSnapshotFSLMocker.model';
import { BrCancelMockerRequest } from '../model/brcancelmocker.model';
import { ShipmentLabelMockerService } from '../service/shipmentlabel-mocker.service';
import { InvsnapshotFSLService } from '../service/invsnapshotFSL.service';
@Component({
  selector: 'app-mockersformly',
  templateUrl: './mockersformly.component.html',
  styleUrls: ['./mockersformly.component.css']
})
export class MockersformlyComponent implements OnInit {
  private logger: log.AppInsightsLogger;
  partnerProfile: OutboundDC;
  batchSize: number;
  doNumbers: string;
  poNumbers: string;
  mode: string;
  showLoader: boolean;
  logMessage: string = '';
  form = new FormGroup({});
  model: any = {
  };
  options: FormlyFormOptions = {};
  loadedConfig: {} = {}; // name of list => raw value of list as JSON object
  filesAndOptions: any = [{
    "fileName": "vendors",
    "optionsList": "vendorList",
  },
  {
    "fileName": "plantsDC",
    "optionsList": "plantDCList"
  },
  {
    "fileName": "loadplanpartners",
    "optionsList": "partnerList"
  },
  {
    "fileName": "LoadPlanConfig",
    "optionsList": "transportCodeList"
  },

  ]
  fields: FormlyFieldConfig[] = [{}];
  goodReceiptMockRequest: GoodReceiptMockRequest;
  goodReceiptMockResponse: GoodReceiptMockResponse;
  payload: any[];
  correlationId = '';
  loadPlanRequest: LoadPlanMockRequest;
  partnerLists: any[];
  allMockerFields: FormlyFieldConfig[];

  constructor(private masterDataService: MasterDataService, private asnMockerService: AsnMockerService, private tradePorformaService: TradeporformaService,
    private obdMockerService: OutboundMockerService, private scgService: ScgService, private   invsnapshotFSLService: InvsnapshotFSLService, private loadPlanService: LoadPlanService,
    private inventoryService: InventoryServiceService, private http: HttpClient, private shipmentService: ShipmentService,
    private goodReceiptService: GoodReceiptService, private shipmentMockerService: ShipmentmockerService,
    private loadplanMockerService: LoadplanmockerService, private grMockerService: GoodsreceiptmockerService,
    private ackMockerService: AcknowledgeMockerService, private brMockersService: BrMockersService, private shipmentLabelMockerService: ShipmentLabelMockerService) { }

  ngOnInit(): void {
    let stringjson = JSON.stringify(this.filesAndOptions);
    this.loadPlanRequest = new LoadPlanMockRequest();
    this.goodReceiptMockRequest = new GoodReceiptMockRequest()
    this.loadOptionsFiles(stringjson);
    this.http.get('./assets/configuration/mockersFormly.json', { responseType: 'text' }).subscribe(
      (response: string) => {
        let fieldGroup: any = JSON.parse(response)
        this.allMockerFields = [...fieldGroup];
        this.fields = [{
          type: 'dropdown',
        }];
        this.fields[0]["fieldGroup"] = [...this.allMockerFields];
      })
  }
  createFields() {
    this.fields = [...this.fields];
  }
  clearLogs() {
    this.logMessage = '';
  }
  addLogLine(line: string) {
    const currentDateTime = new Date();
    this.logMessage += '[' + currentDateTime.toLocaleString() + '] ' + line + '\n';
  }
  onPostGoodReceiptClick() {
    this.showLoader = true;
    this.logMessage = '';
    this.goodReceiptMockRequest = new GoodReceiptMockRequest();
    this.addLogLine(`Progress Started....`);
    let order = new Orders();
    order.OrderNumber = this.model.goodsReceiptMocker.purchaseOrderNumber;
    order.PrecedingOrderNumber = this.model.goodsReceiptMocker.preceedingOrderNumber;
    this.goodReceiptMockRequest.MessageType = this.model.goodsReceiptMocker.grType;
    this.goodReceiptMockRequest.SenderId = this.model.goodsReceiptMocker.sender;
    this.goodReceiptMockRequest.LoadId = this.model.goodsReceiptMocker.loadId;
    this.goodReceiptMockRequest.CarrierTrackingNumber = this.model.goodsReceiptMocker.carrierTrackingCode;
    this.goodReceiptMockRequest.COO = this.model.goodsReceiptMocker.COO;
    this.goodReceiptMockRequest.SerializedFlag = this.model.goodsReceiptMocker.serializedFlag;

    this.goodReceiptMockRequest.PlantCode = this.model.goodsReceiptMocker.plantCode ? this.model.goodsReceiptMocker.plantCode : '';
    this.goodReceiptMockRequest.Orders = [];
    this.goodReceiptMockRequest.Orders.push(order);
    this.goodReceiptMockRequest.IsSTOorder = this.model.goodsReceiptMocker.stoOrder;

    this.goodReceiptMockRequest.Posttoapi = (this.model.goodsReceiptMocker.Posttoapi) ? true : false;

    this.goodReceiptService.onPostMockLoadPlanRequest(this.goodReceiptMockRequest).subscribe(goodReceiptResponse => {
      if (goodReceiptResponse != null && goodReceiptResponse.length > 0) {
        this.payload = goodReceiptResponse;
        goodReceiptResponse.forEach(element => {
          this.correlationId = element.correlationId;



          if (element.correlationId === '') {
            this.clearLogs()
            this.addLogLine(`Good Receipt failed while posting to GoodsReceipt service`);
            //  this.goodReceiptMockResponse.message = this.grMockerService.getValidJSONObject(element.message);
            this.logger.trackTrace(`Good Receipt failed. correlationId is null`);
          } else {
            if (element.isSuccessful == true) {
              this.clearLogs()

              this.addLogLine(`Message created and posted to GoodsReceipt service successfully.` +
                ` Good Receipt Message: ${element.message}`);
              //this.goodReceiptMockResponse.message = this.grMockerService.getValidJSONObject(element.message);

              this.logger.trackTrace(`Message created and posted to GoodsReceipt service successfully. ` +
                ` Good Receipt Message: ${element.correlationId}`);
            }
            //Good Receipt Message: ${ this.getValidJSONObject(element.message) },
          }
        });
        this.showLoader = false;
      } else {
        this.clearLogs()
        this.addLogLine(`Some error occured: ${goodReceiptResponse}`);
        this.logger.trackTrace(`Good Receipt Mocker, Some error occured`);
        this.showLoader = false;
      }
    }, (error) => {
      this.clearLogs()
      this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      this.logger.trackException(error);
      this.showLoader = false;
    });
  }
  onPostLoadPlanClick() {
    this.showLoader = true;
    let batchCounter = 0;
    this.logMessage = '';
    let entityNumber = '';
    this.loadPlanRequest = new LoadPlanMockRequest();
    this.addLogLine(`Progress Started....`);
    if (this.model.loadplanMocker.deliveryCode) {
      entityNumber = this.model.loadplanMocker.deliveryCode;
    }
    else if (this.model.loadplanMocker.purchaseCode) {
      entityNumber = this.model.loadplanMocker.purchaseCode;
    }
    const entityNumberArray = this.loadplanMockerService.getEntityNumberArray(entityNumber, this.model);
    if (this.model.loadplanMocker.loadplanMode == Constants.LoadPlanModeLoad) {
      this.loadPlanRequest.entityType = this.model.loadplanMocker.entityType;
      const totalNumberOfDos = entityNumberArray.length;
      const batchedArrays = this.loadplanMockerService.getBatchedArrays(entityNumberArray, this.model.loadplanMocker.batchSize);

      this.addLogLine(`Selected LoadPlan Mode: ${Constants.LoadPlanModeLoad}, Number of Orders in file: ${totalNumberOfDos}`);
      this.logger.trackTrace(`Selected LoadPlan Mode: ${Constants.LoadPlanModeLoad}, Number of Orders in file: ${totalNumberOfDos}`);

      batchedArrays.forEach(elements => {
        var loadPlanRequest_Batch = new LoadPlanMockRequest();
        loadPlanRequest_Batch.partnerId = this.model.loadplanMocker.partner;
        loadPlanRequest_Batch.entityNumbers = elements;
        loadPlanRequest_Batch.mode = Constants.LoadPlanModeLoad;
        loadPlanRequest_Batch.entityType = this.model.loadplanMocker.entityType;
        loadPlanRequest_Batch.isConsolidated = this.model.loadplanMocker.consolidateOrder ? true : false;
        this.loadPlanService.postMockLoadPlanRequest(loadPlanRequest_Batch).subscribe(loadPlanResponse => {
          this.addLogLine(`Batch ${++batchCounter} submitted. Batch - ${JSON.stringify(elements)}`);
        }, () => {
          this.addLogLine(`Some error occured for batch : ${batchCounter}". Batch - ${JSON.stringify(elements)}`);
          this.logger.trackTrace(`Loadplan Mocker: Some error occured for batch : ${batchCounter}". Batch - ${JSON.stringify(elements)}`);
        });
      });
      this.showLoader = false;
    }
    else if (this.model.loadplanMocker.loadplanMode == Constants.LoadPlanModeSync) {
      var totalNumberOfDos = entityNumberArray.length;

      this.addLogLine(`Selected LoadPlan Mode: ${Constants.LoadPlanModeSync}, Number of Orders: ${totalNumberOfDos}`);
      this.loadPlanRequest.entityType = this.model.loadplanMocker.entityType;
      this.loadPlanRequest.partnerId = this.model.loadplanMocker.partner;
      this.loadPlanRequest.entityNumbers = entityNumberArray;
      this.loadPlanRequest.mode = Constants.LoadPlanModeSync;
      this.loadPlanRequest.isConsolidated = this.model.loadplanMocker.consolidateOrder ? true : false;
      this.loadPlanRequest.loadPlanParams.transportMethodDescription = this.model.loadplanMocker.transportMethodCode;
      this.loadPlanRequest.loadPlanParams.serviceLevelCode = this.model.loadplanMocker.serviceLevelCode;
      this.loadPlanRequest.loadPlanParams.paymentTermsCode = this.model.loadplanMocker.paymentTermsCode;
      this.loadPlanRequest.loadPlanParams.supplierId = this.model.loadplanMocker.supplierId;
      this.loadPlanRequest.loadPlanParams.BusinessChannel = this.model.loadplanMocker.BusinessChannel;
      this.loadPlanRequest.loadPlanParams.ActionCode = this.model.loadplanMocker.ActionCode;
      this.loadPlanRequest.loadPlanParams.ProcessType = this.model.loadplanMocker.ProcessType;
      this.loadPlanRequest.loadPlanParams.BookingNumber = this.model.loadplanMocker.bookingNumber;

      this.loadPlanService.postMockLoadPlanRequest(this.loadPlanRequest).subscribe((loadPlanResponse: any) => {
        if (loadPlanResponse != null && loadPlanResponse.length > 0) {
          let lpResponse = loadPlanResponse;
          lpResponse.forEach(element => {
            this.addLogLine(`LoadPlan submitted. Order: ${element.doNumber}, LoadPlan Correlation Id: ${element.correlationId}, Message: ${element.message}`);
            this.logger.trackTrace(`LoadPlan submitted. Order: ${element.doNumber}, LoadPlan Correlation Id: ${element.correlationId}, Message: ${element.message}`);
          });
          this.showLoader = false;
        } else {
          this.addLogLine(`Some error occured: ${loadPlanResponse}`);
          this.logger.trackTrace(`Loadplan Mocker ${this.addLogLine}`);
          this.showLoader = false;
        }
      }, (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
        this.logger.trackTrace(`Loadplan Mocker ${this.addLogLine}`);
        this.showLoader = false;
      });
    }
  }
  onPostShipmentClick() {
    const byDoNumbers = this.model.shipmentMocker.shippingType === ShipmentMockRequestType[ShipmentMockRequestType.DC];
    let batchCounter = 0;
    this.showLoader = true;
    this.logMessage = '';
    this.addLogLine(`Progress Started....`);
    const numbersArray = this.shipmentMockerService.getNumberArray(byDoNumbers ? this.model.shipmentMocker.doNumber : this.model.shipmentMocker.poNumber, this.model);

    if (this.model.shipmentMocker.shippingMode === Constants.ShipmentModeLoad) {
      const totalNumberOfOrders = numbersArray.length;
      const batchedArrays = this.shipmentMockerService.getBatchedArrays(numbersArray, this.model.shipmentMocker.batchSize);
      if (batchedArrays.length > 0) {
        batchedArrays.forEach(element => {
          const shipmentRequest = this.shipmentMockerService.createShipmentMockRequest(element, true, this.model);
          this.shipmentService.postMockShipmentRequest(shipmentRequest).subscribe(shipmentResponse => {
            this.addLogLine(`Batch ${++batchCounter} submitted. Batch - ${JSON.stringify(element)}`);
          }, (error) => {
            this.addLogLine(`Some error occured for batch : ${batchCounter}. Batch - ${JSON.stringify(element)}. Error msg - ${JSON.stringify(error)}`);
            this.logger.trackTrace(`In Shipment Mocker, Some error occured for batch`);
            this.logger.trackException(error)
          });
        });
        this.addLogLine(`Selected Shipment Mode: ${this.model.shipmentMocker.shippingMode}, Type: ${this.model.shipmentMocker.shippingType}` +
          `, Number of Delivery Orders in file: ${totalNumberOfOrders}, Total batches: ${batchedArrays.length}`);
        this.logger.trackTrace(`In Shipment Mocker, ${this.addLogLine} `);

      }

      this.showLoader = false;
    } else if (this.model.shipmentMocker.shippingMode === Constants.ShipmentModeSync) {
      const totalNumberOfOrders = numbersArray.length;

      this.addLogLine(`Selected Shipment Mode: ${this.model.shipmentMocker.shippingMode}, Type: ${this.model.shipmentMocker.shippingType}` +
        `, Number of Delivery Orders: ${totalNumberOfOrders}`);

      const shipmentRequest = this.shipmentMockerService.createShipmentMockRequest(numbersArray, false, this.model);

      this.shipmentService.postMockShipmentRequest(shipmentRequest).subscribe(shipmentResponse => {
        if (shipmentResponse != null && shipmentResponse.length > 0) {
          shipmentResponse.forEach(element => {
            const logline = `EntityNumber: ${element.entityNumber}, EntityType: ${element.entityType}`;
            if (element.correlationId === '') {
              this.addLogLine(`Shipment failed. ${logline}, Message: ${element.message}`);
              this.logger.trackTrace(`In Shipment Mocker, Shipment failed. ${logline}, Message: ${element.message} `);
            } else {
              this.addLogLine(`Shipment submitted. ` + logline +
                `, Shipment Correlation Id: ${element.correlationId}, Message: ${element.message}`);
              this.logger.trackTrace(`In Shipment Mocker, Shipment submitted. ` + logline +
                `, Shipment Correlation Id: ${element.correlationId}, Message: ${element.message}`);
            }
          });
          this.showLoader = false;
        } else {
          this.addLogLine(`Some error occured: ${shipmentResponse}`);
          this.logger.trackTrace(`In Shipment Mocker, Some error occured`);
          this.showLoader = false;
        }
      }, (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
        this.logger.trackTrace(`In Shipment Mocker, Some error occured: ${JSON.stringify(error)} `);
        this.logger.trackException(error)
        this.showLoader = false;
      });
    }
  }
  onPostInventoryAdjClick() {
    const InventoryAdjRequest = new InventoryAdjMockRequest();
    this.addLogLine(`Progress Started....`);
    InventoryAdjRequest.MessageType = 'inventoryadjustment';

    const parameter = new Parameter();
    const adj = new AdjDetail()
    parameter.Action = this.model.InventoryAdjSCG.Action;
    parameter.SenderPlant = this.model.InventoryAdjSCG.SenderPlant;
    parameter.Sender = this.model.InventoryAdjSCG.Sender;

    if (this.model.InventoryAdjSCG.AdjustmentDetail.length > 0) {
      parameter.AdjustmentDetail = [...this.model.InventoryAdjSCG.AdjustmentDetail]
    }
    InventoryAdjRequest.Parameters = parameter;

    this.inventoryService.inventoryMockShipmentRequest(InventoryAdjRequest).subscribe(inventoryResponse => {
      if (inventoryResponse) {
        const logline = `Response Payload: ${inventoryResponse}`;
        this.addLogLine(`Response Payload: ${inventoryResponse}`);
        if (this.model.InventoryAdjSCG.postToScg) {
          this.addLogLine(`Submitting to SCG`);
          this.scgService.onPostToSCG(inventoryResponse, "InventoryAdjustments").subscribe(afterPosToSCGResponse => {
            this.addLogLine(`Response Payload: ${afterPosToSCGResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
            }
          );
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }
  onPostInventorySnapshotFSLClick() {
    const InvSnapshotFSLMocker = new InvSnapshotFSLMockerRequest();
    this.addLogLine(`Progress Started....`);
    InvSnapshotFSLMocker.MessageType = 'InventoryAdvice';
    const parameter = new Parameters();
    parameter.Action = this.model.InvSnapshotFSLMocker.messageAction;
    parameter.SenderPlant = this.model.InvSnapshotFSLMocker.BatchSize.toString();
    parameter.MessageSender = this.model.InvSnapshotFSLMocker.messageSender;
    parameter.Supplier = this.model.InvSnapshotFSLMocker.supplier;
    parameter.SupplierNodelocation = this.model.InvSnapshotFSLMocker.supplierNodelocation;
    parameter.ReportingPlant = this.model.InvSnapshotFSLMocker.reportingPlant;
    parameter.InventoryAdviceLines = [];
    if (this.model.InvSnapshotFSLMocker.inventoryAdviceLines) {
      const inventoryAdviceLine = new InventoryAdviceLine();
      this.model.InvSnapshotFSLMocker.inventoryAdviceLines.forEach(element => {
        inventoryAdviceLine.ItemStatusType = element.itemStatusType;
        inventoryAdviceLine.ShipToPlant = element.shipToPlant ? element.shipToPlant : '';
        inventoryAdviceLine.ShipFromPlant = element.shipFromPlant ? element.shipFromPlant : '';
        inventoryAdviceLine.FromInventoryLocationId = element.fromInventoryLocationId;
        inventoryAdviceLine.Quantity = element.quantity;
        inventoryAdviceLine.ItemSKU = element.itemSKU;
        inventoryAdviceLine.VendorItemSKU = element.vendorItemSKU;
        inventoryAdviceLine.SerializedItemIndicator = element.serializedItemIndicator ? true : false;
        if (element.itemSerialNumbers) {
          let itemSerialNumbers = element.itemSerialNumbers.split(',');
          let itemSerialNumbersArray = itemSerialNumbers.map(function (item) {
            return item.trim();
          });
          inventoryAdviceLine.ItemSerialNumbers = itemSerialNumbersArray ? itemSerialNumbersArray : [];
        }
        else {
          inventoryAdviceLine.ItemSerialNumbers = [];
        }
        parameter.InventoryAdviceLines.push(inventoryAdviceLine);
      });
    }

    InvSnapshotFSLMocker.Parameters = parameter;

    this.inventoryService.inventorySnapshotFSLMockerRequest(InvSnapshotFSLMocker).subscribe(inventoryResponse => {
      if (inventoryResponse) {
        const logline = `Response Payload: ${inventoryResponse}`;
        this.addLogLine(`Response Payload: ${inventoryResponse}`);
        if (this.model.InvSnapshotFSLMocker.postToInvSnaphotFSL) {
          this.addLogLine(`Submitting to Inventory Snapshot Service`);
          this.invsnapshotFSLService.onPostToInvSnapshot(inventoryResponse).subscribe(afterPosToInvResponse => {
            this.addLogLine(`Response Payload: ${afterPosToInvResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
            }
          );
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }

    )
  }
  onPostAcknowledgementClick() {
    this.addLogLine(`Progress Started....`);
    const ackRequest = new AckknowledgementMockerRequest();
    const parameterAck = new ParameterAck();
    const transaction = new Transac();
    transaction.Reasons = [];
    transaction.Identifier = [];
    ackRequest.MessageType = "acknowledgement";

    parameterAck.Action = this.model.AcknowledgeSCG.Action;
    parameterAck.MessageBusinessChannel = this.model.AcknowledgeSCG.MessageBusinessChannel;
    parameterAck.MessageSubBusinessChannel = this.model.AcknowledgeSCG.MessageSubBusinessChannel;
    parameterAck.Sender = this.model.AcknowledgeSCG.Sender;

    transaction.Identifier = [...this.model.AcknowledgeSCG.Transaction.Identifier];
    transaction.Reasons = this.model.AcknowledgeSCG.Transaction.Status !== 'Accepted' ? [...this.model.AcknowledgeSCG.Transaction.Reasons] : [];
    transaction.Status = this.model.AcknowledgeSCG.Transaction.Status;
    transaction.Type = this.model.AcknowledgeSCG.Transaction.Type;
    parameterAck.Transaction = transaction;
    ackRequest.Parameters = parameterAck;
    this.ackMockerService.ackMockShipmentRequest(ackRequest).subscribe(ackMockerResponse => {
      if (ackMockerResponse) {

        const logline = `Response Payload: ${ackMockerResponse}`;
        this.addLogLine(`Response Payload: ${ackMockerResponse}`);
        if (this.model.AcknowledgeSCG.postToScg) {
          this.addLogLine(`Submitting to SCG`);
          this.scgService.onPostToSCG(ackMockerResponse, "Acknowledgements").subscribe(afterPosToSCGResponse => {
            this.addLogLine(`Response Payload: ${afterPosToSCGResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
            }
          );
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }

  onPostOutboundSCGClick() {
    const obdRequest = new OutboundDeliveryMockerRequest();
    this.addLogLine(`Progress Started....`);
    obdRequest.MessageType = "deliveryorder";
    const parameterObd = new ParameterOBD();
    parameterObd.MessageBusinessChannel = this.model.obdSCG.MessageBusinessChannel;
    parameterObd.MessageSubBusinessChannel = this.model.obdSCG.MessageSubBusinessChannel;
    parameterObd.MessagePartyId = this.model.obdSCG.MessagePartyId;
    parameterObd.MessageAction = this.model.obdSCG.MessageAction;
    parameterObd.LoadPlanId = this.model.obdSCG.LoadPlanId;
    parameterObd.OrderCID = this.model.obdSCG.OrderCID;

    obdRequest.Parameters = parameterObd;
    this.obdMockerService.obdMockShipmentRequest(obdRequest).subscribe(obdMockerResponse => {
      if (obdMockerResponse) {

        const logline = `Response Payload: ${obdMockerResponse}`;
        this.addLogLine(`Response Payload: ${obdMockerResponse}`);
        if (this.model.obdSCG.postToScg) {
          this.addLogLine(`Submitting to SCG`);
          this.scgService.onPostToSCG(obdMockerResponse, "DeliveryOrders").subscribe(afterPosToSCGResponse => {
            this.addLogLine(`Response Payload: ${afterPosToSCGResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
            }
          );
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }
  onPostASNSCGClick() {
    const asnRequest = new AsnMockerRequest();
    this.addLogLine(`Progress Started....`);
    asnRequest.MessageType = "shipment";
    const parameterASN = new ParameterASN();
    parameterASN.MessageBusinessChannel = this.model.AsnMockerScg.MessageBusinessChannel;
    parameterASN.MessageSubBusinessChannel = this.model.AsnMockerScg.MessageSubBusinessChannel;
    parameterASN.MessagePartyId = this.model.AsnMockerScg.MessagePartyId;
    parameterASN.MessageAction = this.model.AsnMockerScg.MessageAction;
    parameterASN.DeliveryCID = this.model.AsnMockerScg.DeliveryCID;

    asnRequest.Parameters = parameterASN;
    this.asnMockerService.asnMockShipmentRequest(asnRequest).subscribe(asnMockerResponse => {
      if (asnMockerResponse) {

        const logline = `Response Payload: ${asnMockerResponse}`;
        this.addLogLine(`Response Payload: ${asnMockerResponse}`);
        if (this.model.AsnMockerScg.postToScg) {
          this.addLogLine(`Submitting to SCG`);
          this.scgService.onPostToSCG(asnMockerResponse, "Shipments").subscribe(afterPosToSCGResponse => {
            this.addLogLine(`Response Payload: ${afterPosToSCGResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
            }
          );
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }
  onPostTradePorformaClick() {
    const tradePorformaRequest = new TradeProformaMockerRequest();
    this.addLogLine(`Progress Started....`);
    tradePorformaRequest.DeliveryOrderDetails = [];
    tradePorformaRequest.UseDummyDoIfNecessary = this.model.tradeProformaMocker.UseDummyDoIfNecessary;
    const parametertrade = new TradeProformaParams();
    parametertrade.BillingType = this.model.tradeProformaMocker.billingType;
    parametertrade.ConsolidationFlag = this.model.tradeProformaMocker.consolidationFlag ? "Y" : "N";
    parametertrade.CountryofOrigin = this.model.tradeProformaMocker.countryOfOrigin;
    parametertrade.RepeatFlag = this.model.tradeProformaMocker.repeatFlag ? "Y" : "N";
    tradePorformaRequest.DeliveryOrderDetails = this.model.tradeProformaMocker.deliveryOrderNumber;
    tradePorformaRequest.TradeProformaParams = parametertrade;
    this.tradePorformaService.tradePorformaRequest(tradePorformaRequest).subscribe(tradePorformaResponse => {
      if (tradePorformaResponse) {
        const tradevalue = tradePorformaResponse.value;
        tradevalue.forEach(element => {
          if (element.correlationId === '') {
            this.addLogLine(`Trade Proforma failed. Message: ${element.message}`);
            this.logger.trackTrace(`In Shipment Mocker, Shipment failed., Message: ${element.message} `);
          } else {
            this.addLogLine(`Trade Proforma Completed. ` +
              `, Shipment Correlation Id: ${element.correlationId}, Message: ${element.message}`);
            this.logger.trackTrace(`In Shipment Mocker, Shipment submitted. ` +
              `, Shipment Correlation Id: ${element.correlationId}, Message: ${element.message}`);
          }
        });
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }

  onPostBrCreateSCGClick() {
    this.addLogLine(`Mocker Started, the details will be available below shortly`);

    const brCreateMockerRequest = new BrCreateMockerRequest();

    brCreateMockerRequest.Parameters = this.model.BrCreateMockerScg;

    this.brMockersService.brCreateMock(brCreateMockerRequest).subscribe(brCreateMockerResponse => {
      if (brCreateMockerResponse) {
        this.addLogLine(`Mocker Response Fetched`);
        if (this.model.BrCreateMockerScg.postToScg) {
          this.addLogLine(`Submitting to SCG`);
          this.scgService.onPostToSCG(brCreateMockerResponse, "BookingRequests").subscribe(afterPosToSCGResponse => {
            this.addLogLine(`SCG Response Payload: ${afterPosToSCGResponse}`);
            this.addLogLine(`Mocker Response Payload: ${brCreateMockerResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
              this.addLogLine(`Mocker Response Payload: ${brCreateMockerResponse}`)
            }
          );
        }
        else
        {
          this.addLogLine(`Mocker Response Payload: ${brCreateMockerResponse}`);
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }

  onPostBrCancelSCGClick() {
    this.addLogLine(`Mocker Started, the details will be available below shortly`);

    const brCancelMockerRequest = new BrCancelMockerRequest();

    brCancelMockerRequest.Parameters = this.model.BrCancelMockerScg;

    this.brMockersService.brCancelMock(brCancelMockerRequest).subscribe(brCancelMockerResponse => {
      if (brCancelMockerResponse) {
        this.addLogLine(`Mocker Response Fetched`);
        if (this.model.BrCancelMockerScg.postToScg) {
          this.addLogLine(`Submitting to SCG`);
          this.scgService.onPostToSCG(brCancelMockerResponse, "BookingRequests").subscribe(afterPosToSCGResponse => {
            this.addLogLine(`SCG Response Payload: ${afterPosToSCGResponse}`);
            this.addLogLine(`Mocker Response Payload: ${brCancelMockerResponse}`);
          },
            (error) => {
              this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
              this.addLogLine(`Mocker Response Payload: ${brCancelMockerResponse}`)
            }
          );
        }
        else
        {
          this.addLogLine(`Mocker Response Payload: ${brCancelMockerResponse}`);
        }
      }
    },
      (error) => {
        this.addLogLine(`Some error occured: ${JSON.stringify(error)}`);
      }
    )
  }

  onPostShipmentLabelClick() {
    this.addLogLine(`Mocker Started, the details will be available below shortly`);
    this.shipmentLabelMockerService.shipmentLabelCreateMock(this.model.ShipmentLabelMocker).subscribe(shipmentLabelMockerResponse => {
      if (shipmentLabelMockerResponse) {
        this.addLogLine(`Mocker Response Fetched`);
        this.addLogLine(`Mocker Response Payload: ${shipmentLabelMockerResponse}`);
      }
    });
  }

  submit() {
    this.clearLogs();
    let selectedMockerValue = localStorage.getItem('selectedMocker');
    if (selectedMockerValue == 'shipmentMocker') {
      this.onPostShipmentClick();
    }
    else if (selectedMockerValue == 'loadplanMocker') {
      this.onPostLoadPlanClick();
    }
    else if (selectedMockerValue == 'goodsReceiptMocker') {
      this.onPostGoodReceiptClick();
    }
    else if (selectedMockerValue == 'InventoryAdjSCG') {
      this.onPostInventoryAdjClick();
    }
    else if (selectedMockerValue == 'AcknowledgeSCG') {
      this.onPostAcknowledgementClick();
    }
    else if (selectedMockerValue == 'obdSCG') {
      this.onPostOutboundSCGClick();
    }
    else if (selectedMockerValue == 'AsnMockerScg') {
      this.onPostASNSCGClick();
    }
    else if (selectedMockerValue == 'tradeProformaMocker') {
      this.onPostTradePorformaClick();
    }
    else if (selectedMockerValue == 'InvSnapshotFSLMocker') {
      this.onPostInventorySnapshotFSLClick();
    }
    else if (selectedMockerValue == 'BrCreateMockerScg') {
      this.onPostBrCreateSCGClick();
    }
    else if (selectedMockerValue == 'BrCancelMockerScg') {
      this.onPostBrCancelSCGClick();
    }
    else if (selectedMockerValue == 'ShipmentLabelMocker') {
      this.onPostShipmentLabelClick();
    }
  }
  loadOptionsFiles(json: string): void {
    var loadFiles = {}; // map of filename => [optionsList], keep track of which all config files are to be under, under which name
    let parsedUserInput: any = JSON.parse(json);
    parsedUserInput.forEach(ffc => {
      if ((ffc as any).hasOwnProperty('fileName')) {
        let fn: string = ffc['fileName'];         // file name to be loaded
        let opt: string = ffc['optionsList'];     // option list name, this is the name where from options list within form state in read in formly field config

        loadFiles[fn] = loadFiles[fn] || [];
        loadFiles[fn].push(opt);
      }
    });

    var loadFilesPromises = [];
    for (let fn in loadFiles) {
      var promise = this.masterDataService.getSelectOptionsFromFiles(fn).toPromise().then((response: any) => {
        loadFiles[fn].forEach((optListName: string) => {
          this.loadedConfig[optListName] = response;
        });
        this.options.formState = this.loadedConfig;
      },
        (error) => {
          console.log("Can not fetch config file : " + fn, error);
        });

      loadFilesPromises.push(promise);
    }

    Promise.all(loadFilesPromises).then(() => {
      this.createFields();
    });
  }
}
