import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryServiceService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }
  
  inventoryMockShipmentRequest(inventoryMockRequest:any) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<any>(baseUrlWithVersion + '/scgmockers/mockinventoryadjustment', inventoryMockRequest);
  }
  inventorySnapshotFSLMockerRequest(inventorySnapshotFSLMockerRequest:any) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<any>(baseUrlWithVersion + '/InventorySnapshotMock', inventorySnapshotFSLMockerRequest);
  } 
}
