export class BrCancelMockerRequest {
    MessageType: string = "BOOKINGREQUESTCANCEL"
    Parameters: BookingCancelParameters
  }

export class BookingCancelParameters {
    MessageBusinessChannel : string
    MessageSenderPartyId : string
    MessageReceiverPartyId : string
    ShipmentsShipmentBookingNumber : string
    ShipmentsCancellationReasonStatusCode : string
    ShipmentsCancellationReason : string

}