import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentLabelMockerService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }
  shipmentLabelCreateMock(shipmentLabelCreateMockRequest: any) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<any>(baseUrlWithVersion + '/ShipmentLabelMocker/mockshipmentlabel', shipmentLabelCreateMockRequest);
  }
}
