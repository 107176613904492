import { Component, ChangeDetectorRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsalService } from '@azure/msal-angular';
import { Conditions, VerticalsDetails, ScenariosDetails, FlavoursDetails, FormInputsModel, TestRunInputsDetails, TestRunBulkUploadInput, SectionsDetails, ScenariosInputsModel, TestRunsBulkUploadResponse, TestRunsFetchInputs, TestRunsDetails, TestRunsItemDetails, TestRunScenariosDetails, PartnerDetails } from '../../model/execute-testruns.model';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import { MasterDataService } from '../../service/masterdata.service';
import { SampleFilesService } from '../../service/samplefiles.service';
import { ErrorCode } from '../../model/errorcode.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ViewChild, ElementRef } from '@angular/core';
import * as XLSX from 'xlsx';

import { ConfigService } from '../../service/config.service';
import * as log from '../../AppInsightsLogger';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Page } from '../../model/masterdata.model';
import { DescriptionService } from '../../service/description.service';

@Component({
  selector: 'app-execute-testruns',
  templateUrl: './execute-testruns.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./execute-testruns.component.css']
})
export class ExecuteTestRunsComponent implements OnInit {
  static readonly KEYWORD_ID = 'ID';
  static readonly KEYWORD_SCENARIO_CODE = 'ScenarioCode';
  static readonly KEYWORD_FLAVOR_CODE = 'FlavorCode';

  static readonly SAMPLE_FILES: any = {
    'Care,Consumer': SampleFilesService.CareConsumerBulkUploadSample,
    'Deliver,Build to Stock': SampleFilesService.DeliverBuildToStockBulkUploadSample,
    'Deliver,3PP Onboardings': SampleFilesService.Delivery3PPOnboardingsBulkUploadSample,
    'Deliver,Direct Ship': SampleFilesService.DeliverAssembleToOrderBulkUploadSample,
    'WTP DC to RSD,DC to RSD': SampleFilesService.WTPDCToRSDBulkUploadSample,
    'MMF,RSD-SI-RSD': SampleFilesService.MMFRSDToSIBulkUploadSample,
    'MMF,Reverse PO Creation': SampleFilesService.MMFReversePOCreationBulkUploadSample,
    'MMF,RSD-T2-RSD': SampleFilesService.MMFRSDToT2BulkUploadSample,
    'MMF,T2 to RSD': SampleFilesService.MMFT2ToRSDBulkUploadSample
  };

  name: string;
  email: string;
  testRunName: string;
  verticalName: string;
  sectionName: string;
  partnerName: any = [];
  fields: FormlyFieldConfig[];
  model: FormInputsModel;
  scenarioInputsModel: ScenariosInputsModel;
  testRunInputsDetails: TestRunInputsDetails;
  loadedConfig: {} = {}; // name of list => raw value of list as JSON object

  showBulkUploadPanel: boolean;

  headerText: string;
  bodyText: string;
  sidebarWidthClass: string;
  conditions: Conditions;
  verticalsList: VerticalsDetails[];
  glossaryList: any[];
  sectionsList: SectionsDetails[];
  partnerList: PartnerDetails[] = [];
  scenariosList: ScenariosDetails[];
  flavoursList: FlavoursDetails[];
  inputsList: ScenariosInputsModel[];
  tableHeaderJson: any[];
  excludedKeysList = 'id,scenarioId,scenarioName,flavourId,flavourName';
  verticalDescription: string;
  sectionsDescription: string;
  scenariosDescription: string;
  flavoursDescription: string;

  @ViewChild('fileInput') fileInput: ElementRef;
  uploadedFile: any;
  sheetFileName: string;
  sheetTabName: string;
  sheetJsonData: any;
  enableBulkUploadOption: boolean;
  testRunBulkUploadInput: TestRunBulkUploadInput;
  testRunsFetchInputs: TestRunsFetchInputs;
  private logger: log.AppInsightsLogger;

  flavour: string;
  scenario: string;
  requestToClone: TestRunScenariosDetails;
  is500error: boolean = false;
  selectedOrg: string;
  partnerArray: any;
  bpName: any;
  onboardingName: any;
  partnerExist: boolean = false;
  allPartners: any = [];

  constructor(private descriptionService: DescriptionService, private router: Router, private activatedRoute: ActivatedRoute, private configService: ConfigService, private ngxLoader: NgxUiLoaderService, private modalService: NgbModal, private msalService: MsalService, private masterDataService: MasterDataService, private manageTestRunsService: ManageScenariosService, private cdr: ChangeDetectorRef) {
    this.name = this.msalService.instance.getActiveAccount().name;
    this.email = this.msalService.instance.getActiveAccount().username;
    this.logger = new log.AppInsightsLogger(this.configService, this.msalService);
  }

  ngOnInit() {
    this.manageTestRunsService.ngxLoaderConfig.text = 'Loading Test Runs Inputs Schema Details! Please Wait!';
    this.ngxLoader.start();
    this.resetFormControls();
    this.testRunsFetchInputs = new TestRunsFetchInputs();
    this.logger.trackTrace("Successfully landed to Execute Testruns Page");
    this.selectedOrg = window.sessionStorage.getItem("orgSelected");
    this.getOrganization();
    this.loadGlossary();

  }

  ngAfterViewInit() {
    this.loadVerticalsDetails();
    this.cdr.detectChanges();
    //this.getInputMappings();
  }


  resetFormControls() {
    this.sectionsList = [];
    this.scenariosList = [];
    this.flavoursList = [];
    this.partnerList = [];
    this.testRunName = '';
    this.verticalName = '-1';
    this.sectionName = '-1';
    this.partnerName = [];
    this.inputsList = [];
    this.tableHeaderJson = [];
    this.sidebarWidthClass = 'sidebar-normal-width';
    this.fields = null;
    this.model = new FormInputsModel();
    this.conditions = new Conditions();
    this.scenarioInputsModel = new ScenariosInputsModel();
    this.testRunInputsDetails = new TestRunInputsDetails();
    this.resetBulkUploadInput();
  }

  clearForm() {
    this.resetFormControls();
    this.router.navigate(['/execute-testruns']);
  }

  resetBulkUploadInput() {
    this.testRunBulkUploadInput = new TestRunBulkUploadInput();
    this.uploadedFile = null;
    this.sheetFileName = null;
    this.sheetTabName = null;
    this.sheetJsonData = null;
    this.showBulkUploadPanel = false;
    if (this.conditions) {
      this.conditions.isBulkUploadMode = false;
    }
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  resetInputControls() {
    this.model = new FormInputsModel();
    this.sidebarWidthClass = 'sidebar-normal-width';
    this.loadUserInputJson();
  }

  switchInputMode() {
    this.showBulkUploadPanel = !this.showBulkUploadPanel;
  }

  loadGlossary() {
    this.descriptionService.getGlossary().subscribe((response: Page[]) => {
      if (response != null) {
        this.glossaryList = response;

      }

      for (var i = 0; i < this.glossaryList.length; i++) {
        if (this.glossaryList[i].name == "SCOR Function") {
          this.verticalDescription = this.glossaryList[i].description;
        } else if (this.glossaryList[i].name == "Scenario Category (L3)") {
          this.sectionsDescription = this.glossaryList[i].description;
        } else if (this.glossaryList[i].name == "Business Process (L4)") {
          this.scenariosDescription = this.glossaryList[i].description;
        } else if (this.glossaryList[i].name == "Sub-Process") {
          this.flavoursDescription = this.glossaryList[i].description;
        }
      }
    }, (error: any) => {
      if (error.status == 500) {
        this.is500error = true;
      }
      this.logger.trackTrace(`In Execute testruns, load Glossary Details Error`);
      this.logger.trackException(error);
      this.ngxLoader.stop();
    });
  }

  loadVerticalsDetails() {
    this.manageTestRunsService.getVerticalsDetails(parseInt(this.selectedOrg)).subscribe((response: VerticalsDetails[]) => {
      if (response != null) {
        this.verticalsList = response;
        this.getInputMappings();
        this.loadAllConfig();
        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
      }
    }, (error: any) => {
      if (error.status == 500) {
        this.is500error = true;
      }
      this.logger.trackTrace(`In Execute testruns, load Verticals Details Error`);
      this.logger.trackException(error);
      this.ngxLoader.stop();
    });
  }

  loadAllConfig() {
    var loadFiles = {}; // map of filename => [optionsList], keep track of which all config files are to be under, under which name
    //loop over vertical details, load all file info needed
    (this.verticalsList || []).forEach(vertical => {
      (vertical.sectionsList || []).forEach(section => {
        (section.scenariosList || []).forEach(scenario => {
          let userInputJson = scenario.userInputJson;
          try {
            let parsedUserInput: FormlyFieldConfig[] = JSON.parse(userInputJson);
            parsedUserInput.forEach(ffc => {
              if ((ffc as any).hasOwnProperty('fileName')) {
                let fn: string = ffc['fileName'];         // file name to be loaded
                let opt: string = ffc['optionsList'];     // option list name, this is the name where from options list within form state in read in formly field config

                loadFiles[fn] = loadFiles[fn] || [];
                loadFiles[fn].push(opt);
              }
            });
          }
          catch (err) {
            this.logger.trackTrace(`Incorrect JSON config for scenario - ${scenario.scenarioName} (${scenario.scenarioId}), vertical ${vertical.verticalName} -- ${err}`);
            this.logger.trackException(err);
            console.log("Incorrect JSON config ", err);
          }
        });
      });
    });

    // load actual files, and write under the needed names
    for (let fn in loadFiles) {
      this.masterDataService.getSelectOptionsFromFiles(fn).subscribe((response: any) => {
        loadFiles[fn].forEach((optListName: string) => {
          this.loadedConfig[optListName] = response;
        });
      },
        (error) => {
          console.log("Can not fetch config file : " + fn, error);
          this.logger.trackTrace(`Can not load config file : ${fn}`);
        });
    }
  }

  loadSectionsList() {
    if (this.verticalName != '-1') {
      this.sectionsList = this.verticalsList.find(x => x.verticalName == this.verticalName).sectionsList;
      if (this.sectionsList.length == 0) {
        this.sectionName = this.verticalName;
      }
    }
  }

  loadScenariosList() {
    if (this.verticalName != '-1' && this.sectionName != '-1') {
      const currSection = this.sectionsList.filter(x => x.sectionName == this.sectionName)[0];
      this.enableBulkUploadOption = currSection.enableBulkUploadOption;
      this.conditions.allowMultipleTestRuns = currSection.allowMultipleTestRuns;
      if (currSection.scenariosList.filter(x => x.includeAllOption == false).length > 0) {
        this.scenariosList = currSection.scenariosList;
      } else {
        this.scenariosList = [{ scenarioId: 0, scenarioName: 'All', businessProcessName: 'All', businessProcessDefinition: 'All', scenarioDescription: 'All', includeAllOption: true, flavoursList: [], userInputJson: currSection.scenariosList[0].userInputJson }].concat(currSection.scenariosList);
      }
      if (currSection.scenariosList.length == 1 && !this.enableBulkUploadOption) {

        this.model.scenarioId = this.scenariosList[0].scenarioId;
        this.loadFlavoursList();
      }
      if (this.testRunInputsDetails.inputsJsonDetails == undefined || this.testRunInputsDetails.inputsJsonDetails.length <= 0) {
        this.getPartnerDetails();
      }
    }
  }
  loadToggleSidebar($event) {


    if (this.partnerName.length > 0) {
      this.toggleSidebar();
    }
  }
  getPartnerDetails() {
    this.partnerExist = false;
    this.manageTestRunsService.getPartnerDetails().subscribe((response: any) => {
      this.partnerArray = response;
      this.partnerArray.forEach((data: any) => {
        if ((data.vertical == this.verticalName) && (data.section == this.sectionName)) {
          //  this.partnerExist = true;
          var str: any = data.partnerOrgName + '-' + data.businessProcessName;
          this.partnerList.push(str);

          this.allPartners = [];
          this.partnerList.forEach((el: any, index) => {
            this.allPartners.push({ id: index + 1, name: el })
          })

        }
      })
      if (this.partnerList.length > 0) {
        this.partnerExist = true;
      }
      else {
        this.partnerExist = false;
        this.toggleSidebar();
      }


    })
  }
  toggleSidebar() {
    if (this.conditions.sidebarOpened) {
      this.resetInputControls();
      this.conditions.isEditMode = false;
      if (this.inputsList.length >= 0) {
        this.conditions.showAddButton = true;
        this.conditions.allowMultipleTestRuns = true;
      }
    }
    this.conditions.sidebarOpened = !this.conditions.sidebarOpened;
  }

  setCurrentScenarioDefault(scenId: number, flavId: number) {
    if (scenId >= 0 && (!this.conditions.isEditMode || this.conditions.isEditModeLoadComplete)) {
      this.model = new FormInputsModel();
      const currScenario = this.scenariosList.filter(x => x.scenarioId == scenId)[0];
      this.model.scenarioId = currScenario.scenarioId;
      this.model.scenarioName = currScenario.scenarioName;
      if (flavId >= 0) {
        const currFlavour = this.flavoursList.filter(x => x.flavourId == flavId)[0];
        this.model.flavourId = currFlavour.flavourId;
        this.model.flavourName = currFlavour.flavourName;
      }
    }
  }

  loadFlavoursList() {
    if (this.model.scenarioId >= 0) {
      const currScenario = this.scenariosList.filter(x => x.scenarioId == this.model.scenarioId)[0];
      const currSection = this.sectionsList.filter(x => x.sectionName == this.sectionName)[0];
      this.setCurrentScenarioDefault(this.model.scenarioId, -1);
      if ((currScenario.flavoursList.length > 1 || this.model.scenarioId == 0) && currSection.scenariosList.filter(x => x.includeAllOption == false).length <= 0) {
        this.flavoursList = [{ flavourId: 0, flavourName: 'All' }].concat(currScenario.flavoursList);
      } else {
        this.flavoursList = currScenario.flavoursList;
        if (currScenario.flavoursList.length === 1) {
          this.model.flavourId = this.flavoursList[0].flavourId;
        }
      }
    } else {
      this.flavoursList = [];
    }
    this.loadUserInputJson();
  }

  loadUserInputJson() {
    if (this.model.flavourId >= 0) {
      this.setCurrentScenarioDefault(this.model.scenarioId, this.model.flavourId);
      this.fields = null;
      this.fields = JSON.parse(JSON.stringify(JSON.parse(this.scenariosList.filter(x => x.scenarioId == this.model.scenarioId)[0].userInputJson)));
      this.getSidebarWidthClass();
    } else {
      this.fields = null;
    }
  }

  getSidebarWidthClass() {
    if (this.fields != undefined && this.fields != null && this.fields.filter(x => x['type'] == 'repeat').length > 0 && this.fields.filter(x => x['type'] == 'repeat')[0]['fieldArray'] != null && this.fields.filter(x => x['type'] == 'repeat')[0]['fieldArray']['fieldGroup'].length > 3) {
      this.sidebarWidthClass = 'sidebar-extended-width';
    }
    else {
      this.sidebarWidthClass = 'sidebar-normal-width';
    }
  }

  addScenarios(model: FormInputsModel) {
    if (this.tableHeaderJson.length == 0) {
      this.tableHeaderJson = JSON.parse(JSON.stringify(JSON.parse(this.sectionsList.filter(x => x.sectionName == this.sectionName)[0].tableColumnsJson)));
    }
    if (model.scenarioId == 0) {
      for (let i = 0; i < this.scenariosList.length; i++) {
        if (this.scenariosList[i].scenarioId > 0) {
          const newModel = Object.assign({}, model);
          newModel.scenarioId = this.scenariosList[i].scenarioId;
          for (let j = 0; j < this.scenariosList[i].flavoursList.length; j++) {
            if (this.scenariosList[i].flavoursList[j].flavourId > 0) {
              newModel.flavourId = this.scenariosList[i].flavoursList[j].flavourId;
              this.addScenarioDetails(newModel);
            }
          }
        }
      }
    } else if (model.flavourId == 0) {
      for (let i = 0; i < this.flavoursList.length; i++) {
        if (this.flavoursList[i].flavourId > 0) {
          const newModel = Object.assign({}, model);
          newModel.flavourId = this.flavoursList[i].flavourId;
          this.addScenarioDetails(newModel);
        }
      }
    } else {
      this.addScenarioDetails(model);
    }
    this.toggleSidebar();
    this.model = new FormInputsModel();
    this.loadUserInputJson();

  }

  addScenarioDetails(scenarioModel: FormInputsModel) {
    const newModel = Object.assign({}, scenarioModel);
    newModel.id = this.inputsList.length;
    const currScenario = this.scenariosList.filter(x => x.scenarioId == newModel.scenarioId)[0];
    newModel.scenarioName = currScenario.scenarioName;
    newModel.flavourName = currScenario.flavoursList.filter(y => y.flavourId == newModel.flavourId)[0].flavourName;
    this.inputsList.push(this.serializeModel(newModel));

    this.scenario = newModel.scenarioName
    this.flavour = newModel.flavourName;
    this.scenario = newModel.scenarioName;
  }

  filterBySearchId(searchId: string) {
    this.testRunsFetchInputs.searchId = searchId;
    this.testRunsFetchInputs.testRunId = '';

    if (this.selectedOrg != "" && this.selectedOrg != null) {
      this.testRunsFetchInputs.organizationId = parseInt(this.selectedOrg);
    }
    else {
      this.testRunsFetchInputs.organizationId = 1;
    }

    let scenario: any;
    this.manageTestRunsService.getTestRunsDetails(this.testRunsFetchInputs).subscribe((response: TestRunsDetails) => {
      if (response != null) {
        this.filterScenariosAfterLoad(response.testRunsItemDetailsList, searchId)
      }
    });
  }

  filterScenariosAfterLoad(list: TestRunsItemDetails[], searchId: string) {
    if (list.length == 1) {
      list.forEach(element => {
        element.scenariosDetailsList = element.scenariosDetailsList.filter(scenario => scenario.correlationId == searchId);
      });

      let clone: TestRunScenariosDetails = list[0].scenariosDetailsList[0];
      this.requestToClone = clone;
      let vertical = this.requestToClone.verticalName;
      let section = this.requestToClone.sectionName;
      let scenario = this.requestToClone.scenarioName;
      let flavour = this.requestToClone.flavourName;
      let scenarioSet = false;
      try {
        if (vertical != '' && section != '' && scenario != '') {
          this.verticalName = vertical;
          this.loadSectionsList();
          this.sectionName = section;
          this.loadScenariosList();
          this.model.scenarioId = this.scenariosList.find(x => x.scenarioName == scenario).scenarioId;
          scenarioSet = true;
          this.scenario = scenario;
          this.loadFlavoursList();
          if (this.flavoursList != undefined && this.flavoursList != null && this.flavoursList.length > 0) {
            this.model.flavourId = this.flavoursList.find(x => x.flavourName == flavour).flavourId;
            this.flavour = flavour;
          }

          this.loadUserInputJson();
          let userInputs: Object = JSON.parse(this.requestToClone.stepsDetailsList[0].userInput);
          this.autoFillUserInputs(userInputs);
          this.testRunName = list[0].testRunName + "-clone";
        }
        if (scenarioSet == false) {
          this.clearForm();
        }
      } catch {
        //this.resetFormControls();
        this.clearForm();
      }
    }
  }

  processActiveRoute() {
    let searchId = this.activatedRoute.snapshot.params['searchId'] || "";
    let verticalName = this.activatedRoute.snapshot.params['vertical'] || "";
    let sectionName = this.activatedRoute.snapshot.params['section'] || "";
    let scenarioName = this.activatedRoute.snapshot.params['scenario'] || "";
    if (verticalName != "" && sectionName != "" && scenarioName != "") {
      this.verticalName = verticalName;
      this.loadSectionsList();
      this.sectionName = sectionName;
      this.loadScenariosList();
      this.model.scenarioId = this.scenariosList.find(x => x.scenarioName == scenarioName).scenarioId;
      this.scenario = scenarioName;
      this.loadFlavoursList();
    }
    else {
      this.filterBySearchId(searchId);
    }
  }

  private getOrganization() {
    let organizationID = this.activatedRoute.snapshot.params['org'] || "";
    if (organizationID != "" && organizationID != this.selectedOrg) {
      this.selectedOrg = organizationID;
      window.sessionStorage.setItem("orgSelected", this.selectedOrg);
    }
  }

  autoFillUserInputs(userInputs: Object) {
    const keys = [];
    this.fields.forEach(x => keys.push(x.key));
    keys.forEach(key => {
      if (userInputs.hasOwnProperty(key)) {
        if (key == "noOfLines") {
          this.model[key] = parseInt(userInputs[key], 10);
        }
        else {
          let value = userInputs[key];
          this.model[key] = value;
        }
      }
    });
  }

  getInputMappings() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.processActiveRoute();
    })
  }

  serializeModel(scenarioModel: FormInputsModel) {
    const serialModel = new ScenariosInputsModel();
    serialModel.scenarioId = scenarioModel.scenarioId;
    serialModel.flavourId = scenarioModel.flavourId;
    serialModel.scenarioName = scenarioModel.scenarioName;
    serialModel.flavourName = scenarioModel.flavourName;
    serialModel.id = scenarioModel.id;
    for (const key in scenarioModel) {
      if (scenarioModel.hasOwnProperty(key) && this.excludedKeysList.indexOf(key) < 0) {
        serialModel.inputsModel[key] = scenarioModel[key];
      }
    }
    return JSON.parse(JSON.stringify(serialModel));
  }

  deserializeModel(inputModel: ScenariosInputsModel) {
    const deserialModel = new FormInputsModel();
    for (const key in inputModel) {
      if (inputModel.hasOwnProperty(key) && key != 'inputsModel') {
        deserialModel[key] = inputModel[key];
      } else if (key == 'inputsModel') {
        for (const modelKey in inputModel.inputsModel) {
          if (inputModel.inputsModel.hasOwnProperty(modelKey)) {
            deserialModel[modelKey] = inputModel.inputsModel[modelKey];
          }
        }
      }
    }
    return JSON.parse(JSON.stringify(deserialModel));
  }

  getMultipathValue(processModel: any, xpath: string, result: string) {
    if (xpath.indexOf('.') >= 0) {
      if (Array.isArray(processModel)) {
        for (let p = 0; p < processModel.length; p++) {
          result = this.getMultipathValue(processModel[p], xpath, result);
        }
      }
      else {
        var array = xpath.split('.');
        for (let i = 0; i < array.length; i++) {
          var element = processModel[array[i]];
          if (element != undefined && element != null) {
            var firstDot = xpath.match(/\./);
            if (firstDot)
              xpath = xpath.substring(firstDot.index + 1);
            if (Array.isArray(element)) {
              result = this.getMultipathValue(element, xpath, result);
            }
            else {
              processModel = element;
            }
          }
          else {
            return result;
          }
        }
        return processModel;
      }
    }
    else {
      if (Array.isArray(processModel)) {
        for (let p = 0; p < processModel.length; p++) {
          result += (processModel[p][xpath] != undefined && processModel[p][xpath] != null) ? (processModel[p][xpath] + ' ~ ') : '';
        }
      }
      else {
        result += (processModel[xpath] != undefined && processModel[xpath] != null) ? (processModel[xpath] + ' ~ ') : '';
      }
    }
    return result.substring(0, result.lastIndexOf(' ~ '));
  }

  getModelValue(input: ScenariosInputsModel, key: any) {
    let inputValue = '';
    if (this.excludedKeysList.indexOf(key) >= 0) {
      inputValue = input[key] != undefined && input[key] != null ? input[key] : '';
    } else {
      inputValue = input.inputsModel[key] != undefined && input.inputsModel[key] != null ? input.inputsModel[key] : '';
    }
    return inputValue;
  }

  getColumnValue(modelInput: ScenariosInputsModel, modelField: any) {
    const currModel = JSON.parse(JSON.stringify(modelInput));
    let inputValue = '';
    const key = modelField['model'];
    if (key.indexOf('/') >= 0) {
      const fieldArray = key.split('/');
      for (let i = 0; i < fieldArray.length; i++) {
        let modelValue = '';
        if (fieldArray[i].indexOf('.') >= 0) {
          modelValue = this.getMultipathValue(currModel.inputsModel, fieldArray[i], '');
        }
        else {
          modelValue = this.getModelValue(currModel, fieldArray[i]);
        }
        inputValue += (modelValue != undefined && modelValue != null && modelValue != '') ? (modelValue + ' / ') : '';
      }
      inputValue = inputValue != '' ? inputValue.substring(0, inputValue.lastIndexOf(' / ')) : '';
    }
    else if (key.indexOf('.') >= 0) {
      inputValue = this.getMultipathValue(currModel.inputsModel, key, '');
    }
    else {
      inputValue = this.getModelValue(currModel, key);
    }
    return inputValue;
  }

  setCheckboxValue($event: any, index: number, fieldModel: string) {
    const isChecked = $event.currentTarget.checked;
    this.inputsList[index].inputsModel[fieldModel] = isChecked;
  }

  editScenario(id: number) {
    this.conditions.isEditMode = true;
    if (id >= 0) {
      const editModel = Object.assign({}, this.inputsList[id]);
      this.model = JSON.parse(JSON.stringify(this.deserializeModel(editModel)));
      this.loadFlavoursList();
    }
    this.toggleSidebar();
    this.conditions.isEditModeLoadComplete = true;
  }

  deleteScenario(id: number) {
    if (id >= 0) {
      const index = this.inputsList.map(function (d) { return d['id']; }).indexOf(id);
      this.inputsList.splice(index, 1);
    } else {
      this.resetBulkUploadInput();
    }
    if (this.inputsList.length <= 0) {
      this.toggleSidebar();
    }
  }

  copyScenario(id: number) {
    if (id >= 0) {
      const copyModel = JSON.parse(JSON.stringify(this.inputsList[id]));
      copyModel.id = id + 1;
      this.inputsList.splice(id + 1, 0, copyModel);
    }
  }

  updateScenarios(model: FormInputsModel) {
    const updateModel = Object.assign({}, model);
    this.inputsList[updateModel.id] = this.serializeModel(updateModel);
    this.model = new FormInputsModel();
    this.toggleSidebar();
    this.conditions.isEditMode = false;
    this.conditions.isEditModeLoadComplete = false;
    this.loadUserInputJson();
  }

  cancelScenarioUpdate($event: any) {
    this.model = new FormInputsModel();
    this.toggleSidebar();
    this.conditions.isEditMode = false;
    this.conditions.isEditModeLoadComplete = false;
  }

  submitTestRunInputs(responseModal: any) {
    const parent = this;
    this.manageTestRunsService.ngxLoaderConfig.text = 'Storing Test Runs Inputs Details! Please Wait!';
    this.ngxLoader.start();
    var ptName = [];
    var businessName = [];
    var onboardingNameAll: any = [];
    var onboardingName: any;
    this.partnerName.forEach((pN: any) => {
      var arr: any = pN.name.split("-");
      var partnername = arr[0];
      var bpName = arr[1];
      this.partnerArray.forEach((data: any) => {
        if ((data.partnerOrgName == arr[0]) && (data.businessProcessName == arr[1])) {
          onboardingName = data.onboardingName;
        }
      })
      ptName.push(partnername);
      businessName.push(bpName);
      onboardingNameAll.push(onboardingName);

    }
    )
    var concatPartner = ptName.join(",");
    var concatBusinessProcess = businessName.join(",");
    var concatOnboard = onboardingNameAll.join(",");
    this.testRunInputsDetails.testRunName = this.testRunName;
    this.testRunInputsDetails.verticalName = this.verticalName;
    this.testRunInputsDetails.sectionName = this.sectionName;
    this.testRunInputsDetails.userName = this.name;
    this.testRunInputsDetails.userEmail = this.email;
    this.testRunInputsDetails.partnerOrgName = concatPartner;
    this.testRunInputsDetails.businessProcessName = concatBusinessProcess;
    this.testRunInputsDetails.onboardingName = concatOnboard;

    this.testRunInputsDetails.inputsJsonDetails = JSON.stringify(this.inputsList);
    this.logger = new log.AppInsightsLogger(this.configService, this.msalService, this.verticalName, this.sectionName, this.flavour, this.scenario);
    this.logger.trackTrace(`In Execution test run, User selected Vertical: ${this.verticalName}, Section: ${this.sectionName}, Flavour name: ${this.flavour} and Scenario Name: ${this.scenario}`);

    this.manageTestRunsService.storeTestRunsDetails(parent.testRunInputsDetails).subscribe((response: string[]) => {
      if (response != null && response.length > 0) {
        parent.resetFormControls();
        parent.headerText = 'Test Run Created Successfully!';
        this.logger.trackTrace(`In Execute testruns ${parent.headerText}`);
        parent.bodyText = 'Test Run Created Successfully! Please go to "Manage Test Runs" to view the status of the processes!';
        this.logger.trackTrace(`In Execute testruns, ${parent.bodyText}`);
        parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        });
      } else {
        parent.headerText = 'Test Run Creation Failed!';
        this.logger.trackTrace(`In execute testruns, ${parent.headerText}`);
        parent.bodyText = 'Test Run Creation Failed! Please try again later!';
        this.logger.trackTrace(`In execute testruns, ${parent.bodyText}`);
        parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        });
      }
      parent.ngxLoader.stop();
    }, (error: any) => {
      parent.ngxLoader.stop();
      parent.headerText = 'Test Run Creation Failed!';
      this.logger.trackTrace(`In Execute testruns, ${parent.headerText}`);
      parent.bodyText = 'Test Run Creation Failed! Please try again later!';
      this.logger.trackTrace(`In Execute Testruns, ${parent.bodyText}`);
      this.logger.trackException(error);
      parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      });
    });
  }

  submitTestRunBulkUploadInput(responseModal: any) {
    const parent = this;
    this.manageTestRunsService.ngxLoaderConfig.text = 'Storing Test Runs Inputs Details! Please Wait!';
    this.ngxLoader.start();
    this.testRunBulkUploadInput.testRunName = this.testRunName;
    this.testRunBulkUploadInput.userName = this.name;
    this.testRunBulkUploadInput.userEmail = this.email;
    this.testRunBulkUploadInput.sheetJsonData = JSON.stringify(this.sheetJsonData);

    this.manageTestRunsService.testRunsBulkUpload(parent.testRunBulkUploadInput).subscribe((response: TestRunsBulkUploadResponse) => {
      if (response != null && (!response.errors || response.errors.length === 0)) {
        parent.resetFormControls();
        parent.headerText = 'Test Run Created Successfully!';
        this.logger.trackTrace(`In Execute testruns, ${parent.headerText}`);
        parent.bodyText = 'Test Run Created Successfully! Please go to "Manage Test Runs" to view the status of the processes!';
        this.logger.trackTrace(`In Execute testruns, ${parent.bodyText}`);
        parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
          this.logger.trackTrace(`In Execute testruns, ${result}`);
        });
      } else {
        parent.headerText = 'Test Run Creation Failed!';
        this.logger.trackTrace(`In execute testruns, ${parent.headerText}`);
        parent.bodyText = 'Test Run Creation Failed!';
        this.logger.trackTrace(`In Execute Testruns, ${parent.bodyText}`);
        if (response.errors[0].code === ErrorCode.BadArgument) {
          const errorsString = JSON.stringify(
            response.errors,
            (k, v) => Array.isArray(v) && v.length === 0
              ? undefined
              : v
          );
          parent.bodyText += `\n${errorsString}`;
        } else {
          parent.bodyText += ' Please try again later!';
        }
        parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
        });
      }
      parent.ngxLoader.stop();
    }, (error: any) => {
      parent.ngxLoader.stop();
      parent.headerText = 'Test Run Creation Failed!';
      parent.bodyText = 'Test Run Creation Failed!';
      this.logger.trackTrace(`In Execute testruns, ${parent.headerText}`);
      this.logger.trackTrace(`In Execute testruns, ${parent.bodyText}`);
      this.logger.trackException(error);
      if (error && error.error && error.error.errors && error.error.errors.length > 0 && error.error.errors[0].code === ErrorCode.BadArgument) {
        const errorsString = JSON.stringify(
          error.error.errors,
          (k, v) => Array.isArray(v) && v.length === 0
            ? undefined
            : v,
          2
        );
        parent.bodyText += `\n${errorsString}`;
      } else {
        parent.bodyText += ' Please try again later!';
      }
      parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      });
    });
  }

  validateFileType(event: any, responseModal: any) {
    const parent = this;
    const fileName = event.target.files[0].name.toLowerCase();
    const isXlsFile = fileName.indexOf('xls') > 0;
    const isCsvFile = fileName.indexOf('csv') > 0;
    if (event.target.files.length > 0 && (isXlsFile || isCsvFile)) {
      this.uploadedFile = event.target.files[0];
      this.sheetTabName = isXlsFile ? 'Inputs' : 'Sheet1';
      this.sheetFileName = event.target.files[0].name;
      this.conditions.isBulkUploadMode = true;
    } else {
      parent.headerText = 'Invalid File!';
      parent.bodyText = 'You have selected an invalid file! Please select a xlsx or csv file and try again!';
      parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result: any) => {
      });
    }
  }

  addOrUpdateScenariosBulkMode(responseModal: any) {
    const parent = this;
    const readFile = new FileReader();
    readFile.onload = () => {
      let storeData: any;
      storeData = readFile.result;
      const exceldata = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== exceldata.length; ++i) {
        arr[i] = String.fromCharCode(exceldata[i]);
      }
      const workbook = XLSX.read(arr.join(''), { type: 'binary' });
      parent.sheetJsonData = XLSX.utils.sheet_to_json(workbook.Sheets[parent.sheetTabName], { raw: false });

      for (let i = 0; i < parent.sheetJsonData.length; i++) {
        const row = this.sheetJsonData[i];
        const id = row[ExecuteTestRunsComponent.KEYWORD_ID];
        const scenarioCode = row[ExecuteTestRunsComponent.KEYWORD_SCENARIO_CODE];
        const flavorCode = row[ExecuteTestRunsComponent.KEYWORD_FLAVOR_CODE];
        if (!id || !scenarioCode || !flavorCode) {
          parent.resetBulkUploadInput();
          parent.headerText = 'Invalid File!';
          parent.bodyText = `Row ${i + 1}: ${ExecuteTestRunsComponent.KEYWORD_ID}, ${ExecuteTestRunsComponent.KEYWORD_SCENARIO_CODE} and ${ExecuteTestRunsComponent.KEYWORD_FLAVOR_CODE} cannot be empty`;
          parent.modalService.open(responseModal, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result: any) => {
          });
          break;
        }
      }
    };
    readFile.readAsArrayBuffer(this.uploadedFile);
    this.conditions.isEditMode = false;
    this.toggleSidebar();
  }

  downloadSampleFile() {
    const fileKey = `${this.verticalName},${this.sectionName}`;
    SampleFilesService.downloadSampleFile(ExecuteTestRunsComponent.SAMPLE_FILES[fileKey]);
  }
}
