import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class BrMockersService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }
  brCreateMock(brCreateMockRequest: any) {
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
    return this.httpClient.post<any>(baseUrlWithVersion + '/scgmockers/mockbookingrequestcreate', brCreateMockRequest);
  }

 brCancelMock(brCancelMockRequest: any) {
  const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion;
  return this.httpClient.post<any>(baseUrlWithVersion + '/scgmockers/mockbookingrequestcancel', brCancelMockRequest);
}
}
