import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";

const SKU_REGEX = /^[A-Za-z0-9]{3}-[A-Za-z0-9]{5}$/;

export function minlengthValidationMessage(err: any, field: { templateOptions: { minLength: any; }; }) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}
export function skuValidation(control: FormControl, field: FormlyFieldConfig): ValidationErrors {
  const value = control.value;
  if ( SKU_REGEX.test(value)) {
    return null;
  }
 else{
  return { 'skuValidation': true };
 }
  
}
export function skuValidatorMessage(error: any, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" is not a valid SKU. Please enter a valid SKU in this format XXX-XXXXX.`;
}
export function maxlengthValidationMessage(err: any, field: { templateOptions: { maxLength: any; }; }) {
  if (field.templateOptions['multiple'] === true) {
    return `This maximum selection should be ${field.templateOptions.maxLength}`;
  }
  else {
    return `This value should be less than ${field.templateOptions.maxLength} characters`;
  }
}

export function minValidationMessage(err: any, field: { templateOptions: { min: any; }; }) {
  return `This value should be more than ${field.templateOptions.min}`;
}
export function jsonValidationMessage(err: any, field: { templateOptions: { json: any; }; }) {
  return `This value should be a JSON value`;
}
export function maxValidationMessage(err: any, field: { templateOptions: { max: any; }; }) {
  return `This value should be less than ${field.templateOptions.max}`;
}


export function MasterPackValidator(control: FormControl, field: FormlyFieldConfig): ValidationErrors {
  const validateMulitplesOfMasterPack = (control.value["qty"] && control.value["MASTERPACK_Qty"] && (Number(control.value["qty"]) % Number(control.value["MASTERPACK_Qty"])) !== 0) ? { 'masterpack': true } : null;
  if (validateMulitplesOfMasterPack) {
    const qtyControl = control.get("qty");
    qtyControl.setErrors({
      ...(qtyControl.errors || {}),
      "qty": {
        message: `"${control.value["qty"]} is not a multiple of ${control.value["MASTERPACK_Qty"]}"`
      }
    });
  }
  return null;
}
