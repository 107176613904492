import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InvsnapshotFSLService {

  constructor(private httpClient: HttpClient, private configService: ConfigService) { }
  onPostToInvSnapshot(postInvRequest:any) {
    let header = {
      "Content-Type": 'application/json'
    }
    const baseUrlWithVersion = this.configService.omtestSuiteAPI.baseUrl + '/api/' + this.configService.omtestSuiteAPI.apiVersion; 
    return this.httpClient.post<any>(baseUrlWithVersion + `/InventoryServices/PostToInventoryService`, postInvRequest,{headers:header});
  }
}
